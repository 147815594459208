import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-fe547cd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-cont" }
const _hoisted_2 = { class: "common-main" }
const _hoisted_3 = { class: "nav" }
const _hoisted_4 = { class: "tables" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bread_crumb = _resolveComponent("bread-crumb")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_bread_crumb, { color: "#fff" })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_tabs, {
          modelValue: _ctx.activeName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "法律法规库",
              name: "1"
            }),
            _createCommentVNode(" <el-tab-pane label=\"行政案例库\" name=\"2\"></el-tab-pane> "),
            _createVNode(_component_el_tab_pane, {
              label: "司法案例库",
              name: "3"
            }),
            _createCommentVNode(" <el-tab-pane label=\"裁判文书库\" name=\"4\"></el-tab-pane> ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"]),
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          data: _ctx.tableData,
          style: {"width":"100%"},
          "max-height": "770",
          height: "770",
          stripe: "",
          ref: "elTable"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableLabel, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_table_column, {
                key: index,
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: item.align || 'center',
                "show-overflow-tooltip": ""
              }, {
                default: _withCtx((scope) => [
                  (item.prop === 'index')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(scope.$index + 1), 1 /* TEXT */))
                    : (item.prop === 'lawName')
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          to: {
                    name: 'DetailPage',
                    query: { id: scope.row.lawVersionId }
                  },
                          target: "_blank"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, _toDisplayString(scope.row[item.prop]), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                      : (item.prop === 'caseName')
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 2,
                            to: {
                    name: 'DetailCasePage',
                    query: { id: scope.row.caseId }
                  },
                            target: "_blank"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(scope.row[item.prop]), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                        : (item.prop === 'cpwsAlAjzh')
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 3,
                              to: {
                    name: 'CaseLibraryDetail',
                    query: { id: scope.row.caseId }
                  },
                              target: "_blank"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(scope.row[item.prop]), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                          : (item.prop === 'caseNo')
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 4,
                                to: {
                    name: 'DetailCasePageV2',
                    query: { id: scope.row.id }
                  },
                                target: "_blank"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, _toDisplayString(scope.row[item.prop]), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                            : (item.prop === 'operate')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                  _createCommentVNode(" <el-popconfirm\n                v-else-if=\"item.prop === 'operate'\"\n                title=\"确认要取消此收藏吗\"\n                @confirm=\"unCollectClick(scope.row)\"\n              > "),
                                  _createElementVNode("div", {
                                    class: "collect",
                                    onClick: ($event: any) => (_ctx.unCollectClick(scope.row))
                                  }, "取消收藏", 8 /* PROPS */, _hoisted_6)
                                ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                                  _createCommentVNode(" </el-popconfirm> "),
                                  _createElementVNode("div", null, _toDisplayString(scope.row[item.prop]), 1 /* TEXT */)
                                ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "width", "align"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_el_pagination, {
            total: _ctx.total,
            currentPage: _ctx.currentPage,
            "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
            "page-size": _ctx.pageSize,
            "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageSize) = $event)),
            "page-sizes": [10, 20, 30, 40, 50],
            background: "",
            layout: "total, sizes,prev, pager, next, jumper",
            "prev-text": "上一页",
            "next-text": "下一页",
            onSizeChange: _ctx.handleSizeChange,
            onCurrentChange: _ctx.handleCurrentChange
          }, null, 8 /* PROPS */, ["total", "currentPage", "page-size", "onSizeChange", "onCurrentChange"])
        ])
      ])
    ])
  ]))
}